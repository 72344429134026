.story-edit-container {
  margin-top: 24px;

  form {
    padding: 16px 100px 16px 16px;
    background-color: white;
  }
  .form-upload-component .preview-img-item {
    width: 375px;
    height: 200px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }

  .title-item {
    display: flex;
    margin-bottom: 8px;

    .ant-form-item-label {
      margin-right: 8px;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.story-edit-modal {
  width: 416px !important;
  padding: 0;

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .preview-box {
    padding: 30px 0;
    width: 416px;
    height: 870px;
    text-align: center;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    transform: scale(0.7) translateY(-125px);

    .article-content {
      display: inline-block;
      width: 375px;
      height: 668px;
      margin-top: 65px;
      padding: 16px;
      background-color: white;
      text-align: left;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
