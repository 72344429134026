.story-container article {
  padding: 40px;
  background-color: white;
}
.story-container article h1 {
  display: flex;
  justify-content: center;
}
.story-container article h1 span {
  flex: 1;
}
.story-container .cover-img {
  width: 375px;
  height: 200px;
  object-fit: cover;
}
.story-container .status.hidden {
  color: #f5222d;
}
.story-container .status.disabled {
  color: #999;
}
.story-container .status.rejected {
  color: #f5222d;
}
.story-container .status.reviewing {
  color: #1890ff;
}
.story-container .status.created {
  color: darkorange;
}
.story-container .status.normal {
  color: #999;
}
.story-container .stat-box {
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
}
.story-container .stat-box > span {
  margin-right: 24px;
}
