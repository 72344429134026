.upload-file .upload-file-file {
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  line-height: 1.5715;
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  user-select: none;
  touch-action: manipulation;
  max-width: 100%;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
}
.upload-file .upload-file-file.status-error {
  color: red;
  border-color: red;
}
.upload-file .upload-file-file > span {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.upload-file .upload-file-file > .anticon {
  flex: 0 0 auto;
}
.upload-file .upload-file-file > .anticon + span,
.upload-file .upload-file-file > span + .anticon {
  margin-left: 8px;
}
.upload-file .upload-file-file > .upload-file-file-delete {
  opacity: 0;
  transition: all 0.3s ease;
}
.upload-file .upload-file-file:hover > .upload-file-file-delete {
  opacity: 1;
}
.upload-file .upload-file-upload {
  margin-right: 8px;
  margin-bottom: 8px;
  max-width: 100%;
  height: 32px;
  overflow: hidden;
}
.upload-file-block .ant-upload {
  width: 100%;
}
.upload-file-block .upload-file-file {
  display: flex;
  width: 100%;
}
.upload-file-block .upload-file-upload {
  display: block;
  width: 100%;
}
