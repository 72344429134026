.story-container {
  article {
    padding: 40px;
    background-color: white;

    h1 {
      display: flex;
      justify-content: center;
      span {
        flex: 1;
      }

      a {
      }
    }
  }
  .cover-img {
    width: 375px;
    height: 200px;
    object-fit: cover;
  }
  .status {
    &.hidden {
      color: #f5222d;
    }
    &.disabled {
      color: #999;
    }
    &.rejected {
      color: #f5222d;
    }
    &.reviewing {
      color: #1890ff;
    }
    &.created {
      color: darkorange;
    }
    &.normal {
      color: #999;
    }
  }
  .stat-box {
    margin: 16px 0;
    padding-bottom: 16px;
    border-bottom: 1px solid #e8e8e8;
    & > span {
      margin-right: 24px;
    }
  }
}
