.photos-container {
  margin-top: 24px;
  padding: 24px;
  background-color: white;
}
.photos-container .upload-box {
  margin: 40px 0;
}
.photos-container .photo-item {
  display: inline-block;
  margin: 5px;
  width: 202px;
}
.photos-container .photo-item .ant-card-body {
  padding: 0;
}
.photos-container .photo-item .image-cover {
  position: relative;
  height: 200px;
  width: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.photos-container .photo-item .image-cover .cover-mark {
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 10px;
  text-align: center;
  font-weight: bold;
  color: white;
  background-color: #ff3b3b;
  border-bottom-right-radius: 8px;
}
