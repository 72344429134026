/** 全局主色 */
/** 链接色 */
/** 成功色 */
/** 警告色 */
/** 错误色 */
/** 主字号 */
/** 标题色 */
/** 主文本色 */
/** 次文本色 */
/** 失效色 */
/** 组件/浮层圆角 */
/** 边框色 */
/** 浮层阴影 */
.subscribe-container {
  margin-top: 24px;
}
.subscribe-container .info-box,
.subscribe-container .tabs {
  padding: 24px 16px;
  background-color: white;
}
.subscribe-container .tabs {
  margin-top: 16px;
}
.subscribe-container .info-box td:first-child {
  width: 150px;
}
.subscribe-container .collection tr td:first-child {
  width: 150px;
}
.subscribe-container .top-pane {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
}
.subscribe-container .top-pane i {
  margin-left: 8px;
  transition: color 0.2s;
  cursor: pointer;
  user-select: none;
}
.subscribe-container .top-pane i:hover {
  color: #1890ff;
}
.subscribe-container .status-tip {
  margin: 16px 0;
}
.subscribe-container .important-info {
  color: #1890ff;
  font-weight: bold;
}
.subscribe-container h3 {
  margin-bottom: 16px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
}
.subscribe-container h3:not(:first-child) {
  margin-top: 16px;
}
.subscribe-container h3 .tip {
  display: inline-block;
  margin-left: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}
.subscribe-container .review-box .review-content {
  margin: 16px 0;
  word-break: break-all;
}
.subscribe-container .contract-box {
  text-align: right;
}
.subscribe-container .contract-box .ant-btn {
  margin: 16px 0;
}
.subscribe-container .user-data-box .link {
  display: inline-block;
  margin: 4px 8px;
  color: #1890ff;
  font-size: 14px;
}
.subscribe-container .user-data-box .no-link {
  display: inline-block;
  margin: 4px 8px;
  font-size: 14px;
}
.subscribe-container .user-data-box .row {
  display: flex;
  justify-content: center;
}
.subscribe-container .user-data-box .row .col:first-child {
  width: 80px;
  height: 30px;
  line-height: 30px;
}
.subscribe-container .user-data-box .row .col:last-child {
  flex: 1;
  font-size: 0;
}
.subscribe-container .user-data-box .questions a,
.subscribe-container .user-data-box .questions span {
  display: block;
  margin: 16px 0 8px;
}
.subscribe-container .user-data-box .questions .create-time {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}
.subscribe-container .upload-contract-box {
  display: flex;
  align-items: center;
  margin: 24px 0;
}
.subscribe-container .upload-contract-box .upload-text {
  margin-left: 16px;
  color: #f5222d;
}
.subscribe-container .btn {
  color: #1890ff;
  user-select: none;
  cursor: pointer;
}
.subscribe-container .disabled {
  color: rgba(0, 0, 0, 0.45);
  user-select: none;
  cursor: not-allowed;
}
.subscribe-container .status-item {
  color: rgba(0, 0, 0, 0.65);
}
.subscribe-container .status-item.rejected {
  color: #f5222d;
}
.subscribe-container .status-item.reviewing {
  color: #faad14;
}
.subscribe-container .status-item.normal {
  color: rgba(0, 0, 0, 0.65);
}
.subscribe-status-edit-modal .ant-radio-group {
  width: 100%;
}
.subscribe-status-edit-modal .block {
  display: block;
  margin: 16px 0;
}
.subscribe-status-edit-modal .note-box {
  display: flex;
  padding: 0 24px;
  margin-bottom: 16px;
}
.subscribe-status-edit-modal .note-box .required-star {
  margin-right: 8px;
  color: #f5222d;
}
.subscribe-status-edit-modal .note-box input {
  flex: 1;
}
