.order-detail-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}
.info-card {
  border: 1px solid red;
  width: 20%;
  min-width: 400px !important;
  margin: 20px 0 0 20px;
}
.order-info-card,
.service-info-card,
.action-card {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 16px;
}
.action-card {
  display: flex;
  gap: 8px;
}
.operation-area {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.avatar-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
}
.avatar-uploader:hover {
  border-color: #1890ff;
}
