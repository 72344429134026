/** 全局主色 */
/** 链接色 */
/** 成功色 */
/** 警告色 */
/** 错误色 */
/** 主字号 */
/** 标题色 */
/** 主文本色 */
/** 次文本色 */
/** 失效色 */
/** 组件/浮层圆角 */
/** 边框色 */
/** 浮层阴影 */
.offer-page-containers {
  margin-top: 24px;
}
.offer-page-containers .offer-box {
  padding: 16px;
  background-color: white;
}
.offer-page-containers .btns-box {
  padding-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
}
.offer-page-containers .add-box {
  margin-bottom: 24px;
}
.offer-page-containers .offer-item {
  display: inline-block;
  margin: 5px;
  width: 202px;
}
.offer-page-containers .offer-item.plus {
  display: inline-block;
  height: 313px;
  font-size: 90px;
  font-weight: bold;
  vertical-align: top;
  line-height: 313px;
  text-align: center;
  color: #cccccc;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s;
}
.offer-page-containers .offer-item.plus:hover {
  color: #39f;
}
.offer-page-containers .offer-item .ant-card-body {
  padding: 0;
}
.offer-page-containers .offer-item .image-cover {
  position: relative;
  height: 200px;
  width: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.offer-page-containers .offer-item .image-cover .cover-mark {
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 10px;
  font-weight: bold;
  color: white;
  background-color: #f759ab;
}
.offer-page-containers .offer-item .image-cover .cover-mark.hidden {
  background-color: #f5222d;
}
.offer-page-containers .offer-item .image-cover .cover-mark.disabled {
  background-color: #8c8c8c;
}
.offer-page-containers .offer-item .image-cover .cover-mark.rejected {
  background-color: #f5222d;
}
.offer-page-containers .offer-item .image-cover .cover-mark.reviewing {
  background-color: #faad14;
}
.offer-page-containers .offer-item .image-cover .cover-mark.created {
  background-color: #333333;
}
.offer-page-containers .offer-item .image-cover .cover-mark.normal {
  background-color: #7cb305;
}
.offer-page-containers .offer-item .image-cover .cover-mark.update_reviewing {
  background-color: #faad14;
}
.offer-page-containers .offer-item .image-cover .cover-mark.update_rejected {
  background-color: #f5222d;
}
.offer-page-containers .offer-item .school-name {
  padding: 16px;
  text-align: center;
  font-weight: bold;
  color: #333;
  border-top: 1px solid #f1f2f3;
}
