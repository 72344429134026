.container-index-layout {
  min-height: 100vh;

  .logout-btn {
    cursor: pointer;
    transition: color 0.2s;
    &:hover {
      color: #39f;
    }
  }

  .profile-box {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #082b4a;
    .avatar {
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .username {
      margin-top: 8px;
      align-self: center;
      color: white;
      font-size: 14px;
    }
  }

  .header {
    display: flex;
    height: 48px;
    padding: 0 16px;
    align-items: center;
    line-height: 48px;
    background-color: white;
    .back {
      cursor: pointer;
      user-select: none;
      svg {
        margin-right: 4px;
      }
    }
    .user {
      flex: 1;
      text-align: right;
    }
  }
}
