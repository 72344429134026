.upload-image .upload-image-file {
  display: inline-block;
  vertical-align: top;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.upload-image .upload-image-file.status-error {
  color: red;
  border-color: red;
}
.upload-image .upload-image-file-inner {
  display: grid;
  place-items: center;
  place-content: center;
  gap: 8px;
  position: relative;
  width: 86px;
  height: 86px;
}
.upload-image .upload-image-file-inner .anticon {
  font-size: 18px;
}
.upload-image .upload-image-file-inner > img {
  width: 86px;
  height: 86px;
  object-fit: cover;
  object-position: center;
}
.upload-image .upload-image-file-overlay {
  display: grid;
  place-items: center;
  place-content: center;
  gap: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  height: 86px;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  opacity: 0;
  transition: all 0.3s ease;
}
.upload-image .upload-image-file-overlay:hover {
  opacity: 1;
}
.upload-image .upload-image-upload {
  display: grid;
  place-items: center;
  place-content: center;
  gap: 8px;
  vertical-align: top;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}
.upload-image .upload-image-upload:hover {
  border-color: #1890ff;
  color: #1890ff;
}
.upload-image .upload-image-upload .anticon {
  font-size: 18px;
}
