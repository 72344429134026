.container-plan {
  margin-top: 24px;
  padding: 16px;
  background-color: white;
}
.container-plan .color-green6 {
  color: #52c41a;
}
.container-plan .color-red6 {
  color: #f5222d;
}
