@import '../../var.less';

.subscribes-container {
  margin-top: 24px;

  &.no-permission {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 96px);
    font-size: 15px;
    color: black;
    font-weight: bold;
    text-align: center;
  }

  padding: 24px 16px;
  background-color: white;
  & > header {
    margin: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card {
    min-width: 150px;
    margin-right: 24px;
    user-select: none;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      .total-stat-num {
        color: #1890ff;
      }
    }
  }

  .empty-box {
    flex: 1;
  }

  .ant-card-meta-title {
    padding-bottom: 8px;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
  }

  .big-num {
    font-size: 48px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: darkorange;
  }

  .filter-box {
    margin: 24px 0;
    .ant-select {
      margin-left: 16px;
      width: 160px;
    }
    & > .ant-input-search {
      user-select: none;
    }
    & > .ant-input-search,
    & > .ant-input-search > .ant-input {
      width: 200px;
      margin-right: 16px;
    }
  }

  .status {
    color: @text-color;
    &.cannot_sign,
    &.cannot_contact {
      color: @orange;
    }
    &.not-reviewed {
      color: @red;
    }
    &.cancelled {
      color: @red;
    }
    &.waiting {
      color: @red;
    }
    &.visited,
    &.signed,
    &.contacted {
      color: @text-color-secondary;
    }
    &.reviewed {
      color: @green;
    }
  }
}
