.upload-file {
  .upload-file-file {
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;

    line-height: 1.5715;
    position: relative;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    user-select: none;
    touch-action: manipulation;
    max-width: 100%;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;

    &.status-error {
      color: red;
      border-color: red;
    }

    > span {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > .anticon {
      flex: 0 0 auto;
    }

    > .anticon + span,
    > span + .anticon {
      margin-left: 8px;
    }

    > .upload-file-file-delete {
      opacity: 0;
      transition: all 0.3s ease;
    }
    &:hover > .upload-file-file-delete {
      opacity: 1;
    }
  }

  .upload-file-upload {
    margin-right: 8px;
    margin-bottom: 8px;
    max-width: 100%;
    height: 32px;
    overflow: hidden;
  }
}

.upload-file-block {
  .ant-upload {
    width: 100%;
  }

  .upload-file-file {
    display: flex;
    width: 100%;
  }

  .upload-file-upload {
    display: block;
    width: 100%;
  }
}
