.form-upload-component input {
  padding: 0;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.form-upload-component .preview-img-item {
  position: relative;
  display: inline-block;
  margin: 0 8px;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
}
.form-upload-component .preview-img-item .close-icon {
  position: absolute;
  right: -5px;
  top: -8px;
  width: 10px;
  height: 10px;
  color: red;
  font-weight: bold;
  background-color: white;
}
.form-upload-component .upload-box {
  display: inline-block;
  margin: 0 8px;
  vertical-align: middle;
}
.form-upload-component .plus-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #f5f6f7;
  border-radius: 4px;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
}
.form-upload-component .plus-icon:hover {
  color: #39f;
}
