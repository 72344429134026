.Order__filter-bar-container {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
}
.Order__filter-bar-container .Order__filter-bar-item {
  width: 150px;
  margin: 10px;
}
.Order__filter-bar-container .datepicker-item {
  width: 240px;
}
.Order__filter-bar-container .filter-bar-item--s {
  width: 100px;
}
.Order__filter-bar-container .amount-input {
  width: 110px;
}
.Order__filter-bar-container .amount-input.amount-input--b {
  margin-right: 5px;
}
.Order__filter-bar-container .amount-input.amount-input--s {
  margin-left: 5px;
}
.Order__filter-bar-container .checkbox-group {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  white-space: nowrap;
}
