@import '../../var.less';
.offer-page-containers {
  margin-top: 24px;

  .offer-box {
    padding: 16px;
    background-color: white;
  }
  .btns-box {
    padding-bottom: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .add-box {
    margin-bottom: 24px;
  }
  .offer-item {
    display: inline-block;
    margin: 5px;
    width: 202px;

    &.plus {
      display: inline-block;
      height: 313px;
      font-size: 90px;
      font-weight: bold;
      vertical-align: top;
      line-height: 313px;
      text-align: center;
      color: #cccccc;
      cursor: pointer;
      user-select: none;
      transition: color 0.2s;
      &:hover {
        color: #39f;
      }
    }

    .ant-card-body {
      padding: 0;
    }

    .image-cover {
      position: relative;
      height: 200px;
      width: 200px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;

      .cover-mark {
        position: absolute;
        left: 0;
        top: 0;
        padding: 2px 10px;
        font-weight: bold;
        color: white;
        background-color: #f759ab;
        &.hidden {
          background-color: @hidden;
        }
        &.disabled {
          background-color: @disabled;
        }
        &.rejected {
          background-color: @rejected;
        }
        &.reviewing {
          background-color: @reviewing;
        }
        &.created {
          background-color: @created;
        }
        &.normal {
          background-color: @normal;
        }
        &.update_reviewing {
          background-color: @update_reviewing;
        }
        &.update_rejected {
          background-color: @update_rejected;
        }
      }
    }
    .school-name {
      padding: 16px;
      text-align: center;
      font-weight: bold;
      color: #333;
      border-top: 1px solid #f1f2f3;
    }
  }
}
