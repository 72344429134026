.component-filter {
  margin-top: 10px;
  flex-wrap: wrap;
  display: flex;

  & > * {
    margin-right: 10px;
    margin-bottom: 8px;

    &:first-child {
      margin-left: 0;
    }

    input {
      height: 32px;
      font-size: 13px;
    }
  }
}
