@import '../../var.less';

.order-container {
  margin-top: 24px;
  width: 100%;

  .Order_tabs-box {
    padding: 20px 0 0;
    .Order_tab-item {
      padding: 10px 20px;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      &.Order_tab-item-active {
        color: #1890ff;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-20px);
          display: inline-block;
          width: 40px;
          height: 3px;
          border-radius: 10px;
          background-color: #1890ff;
          margin-top: 10px;
        }
      }
    }
  }

  &.no-permission {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 96px);
    font-size: 15px;
    color: black;
    font-weight: bold;
    text-align: center;
  }

  padding: 24px 16px;
  background-color: white;
  & > header {
    margin: 24px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .card {
    min-width: 150px;
    margin-right: 24px;
    margin-bottom: 10px;
    user-select: none;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      .total-stat-num {
        color: #1890ff;
      }
    }
  }

  .empty-box {
    flex: 1;
  }

  .ant-card-meta-title {
    padding-bottom: 8px;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
  }

  .big-num {
    font-size: 48px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: darkorange;
  }

  .filter-box {
    clear: both;
    max-width: 100%;
    margin: 24px 0;
    overflow: hidden;
  }

  .status {
    color: @text-color;
    &.cannot_sign,
    &.cannot_contact {
      color: @orange;
    }
    &.not-reviewed {
      color: @red;
    }
    &.cancelled {
      color: @red;
    }
    &.waiting {
      color: @red;
    }
    &.visited,
    &.signed,
    &.contacted {
      color: @text-color-secondary;
    }
    &.reviewed {
      color: @green;
    }
  }
}
