.container-index-layout {
  min-height: 100vh;
}
.container-index-layout .logout-btn {
  cursor: pointer;
  transition: color 0.2s;
}
.container-index-layout .logout-btn:hover {
  color: #39f;
}
.container-index-layout .profile-box {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #082b4a;
}
.container-index-layout .profile-box .avatar {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.container-index-layout .profile-box .username {
  margin-top: 8px;
  align-self: center;
  color: white;
  font-size: 14px;
}
.container-index-layout .header {
  display: flex;
  height: 48px;
  padding: 0 16px;
  align-items: center;
  line-height: 48px;
  background-color: white;
}
.container-index-layout .header .back {
  cursor: pointer;
  user-select: none;
}
.container-index-layout .header .back svg {
  margin-right: 4px;
}
.container-index-layout .header .user {
  flex: 1;
  text-align: right;
}
