/** 全局主色 */
/** 链接色 */
/** 成功色 */
/** 警告色 */
/** 错误色 */
/** 主字号 */
/** 标题色 */
/** 主文本色 */
/** 次文本色 */
/** 失效色 */
/** 组件/浮层圆角 */
/** 边框色 */
/** 浮层阴影 */
.subscribes-container {
  margin-top: 24px;
  padding: 24px 16px;
  background-color: white;
}
.subscribes-container.no-permission {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 96px);
  font-size: 15px;
  color: black;
  font-weight: bold;
  text-align: center;
}
.subscribes-container > header {
  margin: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subscribes-container .card {
  min-width: 150px;
  margin-right: 24px;
  user-select: none;
}
.subscribes-container .card:last-child {
  margin-right: 0;
}
.subscribes-container .card:first-child .total-stat-num {
  color: #1890ff;
}
.subscribes-container .empty-box {
  flex: 1;
}
.subscribes-container .ant-card-meta-title {
  padding-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}
.subscribes-container .big-num {
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: darkorange;
}
.subscribes-container .filter-box {
  margin: 24px 0;
}
.subscribes-container .filter-box .ant-select {
  margin-left: 16px;
  width: 160px;
}
.subscribes-container .filter-box > .ant-input-search {
  user-select: none;
}
.subscribes-container .filter-box > .ant-input-search,
.subscribes-container .filter-box > .ant-input-search > .ant-input {
  width: 200px;
  margin-right: 16px;
}
.subscribes-container .status {
  color: rgba(0, 0, 0, 0.65);
}
.subscribes-container .status.cannot_sign,
.subscribes-container .status.cannot_contact {
  color: #faad14;
}
.subscribes-container .status.not-reviewed {
  color: #f5222d;
}
.subscribes-container .status.cancelled {
  color: #f5222d;
}
.subscribes-container .status.waiting {
  color: #f5222d;
}
.subscribes-container .status.visited,
.subscribes-container .status.signed,
.subscribes-container .status.contacted {
  color: rgba(0, 0, 0, 0.45);
}
.subscribes-container .status.reviewed {
  color: #7cb305;
}
