.container-case-info {
  padding-bottom: 20px;
  background: #f5f6f7;

  .color-red6 {
    color: #f5222d !important;
  }

  .ant-form {
    margin: 16px;
  }

  .ant-affix {
    .header-box {
      box-shadow: 0 0px 5px rgba(0, 0, 0, 0.28);
    }
  }

  .header-box {
    background: white;
  }

  .form-box {
    margin-top: 20px;

    .card-box {
      margin-bottom: 10px;
      border-radius: 2px;
    }

    .ant-form-item {
      margin-bottom: 16px !important;
    }
  }

  .ant-affix {
    z-index: 1009;
  }
}
