.container-case-info {
  padding-bottom: 20px;
  background: #f5f6f7;
}
.container-case-info .color-red6 {
  color: #f5222d !important;
}
.container-case-info .ant-form {
  margin: 16px;
}
.container-case-info .ant-affix .header-box {
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.28);
}
.container-case-info .header-box {
  background: white;
}
.container-case-info .form-box {
  margin-top: 20px;
}
.container-case-info .form-box .card-box {
  margin-bottom: 10px;
  border-radius: 2px;
}
.container-case-info .form-box .ant-form-item {
  margin-bottom: 16px !important;
}
.container-case-info .ant-affix {
  z-index: 1009;
}
