/** 全局主色 */
/** 链接色 */
/** 成功色 */
/** 警告色 */
/** 错误色 */
/** 主字号 */
/** 标题色 */
/** 主文本色 */
/** 次文本色 */
/** 失效色 */
/** 组件/浮层圆角 */
/** 边框色 */
/** 浮层阴影 */
.container-case-list {
  margin-top: 24px;
  padding: 16px;
  background-color: white;
}
.container-case-list .color-green6 {
  color: #52c41a;
}
.container-case-list .color-red6 {
  color: #f5222d;
}
