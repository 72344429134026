.component-filter {
  margin-top: 10px;
  flex-wrap: wrap;
  display: flex;
}
.component-filter > * {
  margin-right: 10px;
  margin-bottom: 8px;
}
.component-filter > *:first-child {
  margin-left: 0;
}
.component-filter > * input {
  height: 32px;
  font-size: 13px;
}
