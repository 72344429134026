.container-plan-set-detail {
  background-color: white;
  padding: 16px;
}
.container-plan-set-detail .back-btn {
  margin-bottom: 10px;
}
.container-plan-set-detail .ant-form-item {
  margin-bottom: 8px;
}
.container-plan-set-detail .ant-card-body {
  padding: 12px;
}
.container-plan-set-detail .content-box {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 16px;
  height: calc(100vh - 133px);
}
.container-plan-set-detail .content-box .content-left,
.container-plan-set-detail .content-box .content-right {
  overflow-y: scroll;
}
.container-plan-set-detail .content-box > .title {
  margin: 20px 20px 5px;
}
.container-plan-set-detail .content-box .ant-card-head-title .ant-form-item {
  margin-bottom: 0;
}
.container-plan-set-detail .content-box .deleted-btn {
  margin-left: 20px;
}
.container-plan-set-detail .content-box .ant-card-bordered {
  margin-bottom: 20px;
}
.container-plan-set-detail .content-box .time {
  margin: 30px 0;
}
.container-plan-set-detail .content-box iframe {
  width: 100%;
  height: 100%;
  border: 1px solid #f0f0f0;
}
.container-plan-set-detail .btn-add-block {
  margin-bottom: 16px;
}
.container-plan-set-detail .form-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  place-items: stretch;
  gap: 16px;
}
.container-plan-set-detail .form-grid hr {
  width: 100%;
  border: none;
  border-top: 1px solid #eee;
  margin: 0;
  padding: 0;
}
.container-plan-set-detail .form-grid .form-label {
  padding: 4px 8px;
}
.container-plan-set-detail .form-grid .grid-span2 {
  grid-column: span 2;
}
.container-plan-set-detail .form-grid .grid-span3 {
  grid-column: span 3;
}
.container-plan-set-detail .form-grid .grid-span3-flex {
  grid-column: span 3;
  display: flex;
  justify-content: space-between;
}
.container-plan-set-detail .form-grid .grid-span3-flex .grid-span3-label {
  margin-right: 16px;
  padding: 4px 8px;
  width: 100px;
}
.container-plan-set-detail .form-grid .grid-span3-flex .grid-span3-select {
  flex: 1;
}
.container-plan-set-detail .spin .ant-spin {
  top: 50% !important;
}
