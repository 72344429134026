:root {
  --ck-highlight-marker-blue: #72cdfd;
  --ck-highlight-marker-green: #63f963;
  --ck-highlight-marker-pink: #fc7999;
  --ck-highlight-marker-yellow: #fdfd77;
  --ck-highlight-pen-green: #118800;
  --ck-highlight-pen-red: #e91313;
  --ck-image-style-spacing: 1.5em;
  --ck-todo-list-checkmark-size: 16px;
  --ck-spacing-standard: 11px;
  --ck-z-modal: 2000;
}

.ck.ck-balloon-panel.ck-toolbar-container {
  z-index: ~'calc(var(--ck-z-modal) - 1)';
}

.rich-text-preview-container {
  min-height: 240px;
  max-height: 70vh;
  border: 1px solid #d9d9d9;
  padding: 0 var(--ck-spacing-standard);

  word-break: break-all;
  color: #26343f;
  font-size: 14px;
  line-height: 1.75;
  font-family: 'PingFang SC', 'NotoSansCJKsc', 'DroidSans', 'Roboto', 'Helvetica Neue', 'Hiragino Sans GB',
    'WenQuanYi Micro Hei', 'Microsoft Yahei', sans-serif, Arial, Verdana;

  // & > *:first-child,
  // & > *:first-child > *:first-child {
  //   padding-top: 0;
  //   margin-top: 0;
  // }
  // & > *:last-child,
  // & > *:last-child > *:last-child {
  //   padding-bottom: 0;
  //   margin-bottom: 0;
  // }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 1em 0;
  }

  h1 {
    font-size: 2em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bold;
  }

  h3 {
    font-size: 1.17em;
    font-weight: bold;
  }
  h4 {
    font-weight: bold;
  }

  a {
    color: #26343f;
    border-bottom: 0.1rem dotted #0c83fa;
    transition: all 0.2s;

    &:hover {
      color: #0c83fa;
    }
  }

  figure {
    margin: 1em 0;

    figcaption {
      margin: 0.5em 0;
      font-size: 0.8em;
      text-align: center;
    }
  }
  figure > table,
  figure > img,
  figure > video {
    margin: 0 auto;
  }

  video,
  img,
  table {
    display: block;
    max-width: 100%;
    margin: 1em auto;
  }

  table {
    width: min-content;
    border-collapse: collapse;
    overflow: auto;
    padding: 0;
    border-top: none;
    border-bottom: none;
    border-left: 1px solid #ededed;
    border-right: 1px solid #ededed;
    font-size: 0.8em;
    margin: 1.25em 0;
    color: #666;

    tbody {
      width: 100%;
      display: table;
    }

    tr {
      margin: 0;
      padding: 0;

      td,
      th {
        min-width: 8em;
        margin: 0;
        padding: 0.7rem 0.5rem 0.5rem 0.5rem;
        border: 1px solid #ededed;
        text-align: left;

        &:first-child {
          border-left: none;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
  }

  ol {
    margin: 1em 0;
    padding-left: 2em;
    list-style: initial;
    list-style-type: decimal;

    ol,
    ul {
      margin: 0;
    }

    li {
      list-style: initial;
      list-style-type: decimal;
    }
  }
  ul {
    margin: 1em 0;
    padding-left: 2em;
    list-style: initial;

    ol,
    ul {
      margin: 0;
    }

    li {
      list-style: initial;
    }
  }

  code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: 0.15em;
    border-radius: 2px;
  }

  pre {
    padding: 1em;
    color: #353535;
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;

    code {
      background: unset;
      padding: 0;
      border-radius: 0;
    }
  }

  blockquote {
    overflow: hidden;
    padding: 0 1.5em;
    margin: 1em 0;
    font-style: italic;
    border-left: solid 0.5em hsl(0, 0%, 80%);
  }

  hr {
    border-width: 1px 0 0;
    border-style: solid;
    border-color: hsl(0, 0%, 37%);
    margin: 0;
  }

  .text-indent-1 {
    text-indent: 2em;
  }
  .text-indent-2 {
    text-indent: 4em;
  }
  .text-indent-3 {
    text-indent: 6em;
  }

  // width > 100px
  figure,
  img,
  video,
  table {
    margin: 1em auto;
    max-width: ~'calc(100% - 4em)';
  }

  figure.image-style-side {
    float: right;
    max-width: ~'calc(50% - 4em)';
  }
}

.ck.ck-content {
  .rich-text-preview-container;
}

.ck.ck-content.ck-editor__editable_inline {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    border-color: #40a9ff;
  }
  &.ck-focused,
  &:focus {
    border-color: #40a9ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
    outline: 0;
  }
}

.ant-form-item-has-error .ck.ck-content.ck-editor__editable_inline {
  border-color: #ff4d4f !important;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
}
