@import '../../var';

.subscribe-container {
  margin-top: 24px;

  .info-box,
  .tabs {
    padding: 24px 16px;
    background-color: white;
  }
  .tabs {
    margin-top: 16px;
  }
  .info-box td {
    &:first-child {
      width: 150px;
    }
  }

  .collection {
    tr {
      td {
        &:first-child {
          width: 150px;
        }
      }
    }
  }
  .top-pane {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: bold;
    i {
      margin-left: 8px;
      transition: color 0.2s;
      cursor: pointer;
      user-select: none;
      &:hover {
        color: @primary-color;
      }
    }
  }
  .status-tip {
    margin: 16px 0;
  }
  .important-info {
    color: @primary-color;
    font-weight: bold;
  }
  h3 {
    margin-bottom: 16px;
    font-size: 18px;
    color: @text-color;
    &:not(:first-child) {
      margin-top: 16px;
    }

    .tip {
      display: inline-block;
      margin-left: 8px;
      font-size: 12px;
      color: @text-color-secondary;
    }
  }
  .review-box {
    .review-content {
      margin: 16px 0;
      word-break: break-all;
    }
  }
  .contract-box {
    text-align: right;
    .ant-btn {
      margin: 16px 0;
    }
  }
  .user-data-box {
    .link {
      display: inline-block;
      margin: 4px 8px;
      color: @primary-color;
      font-size: 14px;
    }
    .no-link {
      display: inline-block;
      margin: 4px 8px;
      font-size: 14px;
    }

    .row {
      display: flex;
      justify-content: center;
      .col {
        &:first-child {
          width: 80px;
          height: 30px;
          line-height: 30px;
        }
        &:last-child {
          flex: 1;
          font-size: 0;
        }
      }
    }
    .questions {
      a,
      span {
        display: block;
        margin: 16px 0 8px;
      }
      .create-time {
        font-size: 12px;
        color: @text-color-secondary;
      }
    }
  }

  .upload-contract-box {
    display: flex;
    align-items: center;
    margin: 24px 0;
    .upload-text {
      margin-left: 16px;
      color: @red;
    }
  }

  .btn {
    color: @primary-color;
    user-select: none;
    cursor: pointer;
  }

  .disabled {
    color: @text-color-secondary;
    user-select: none;
    cursor: not-allowed;
  }
  .status-item {
    color: @text-color;
    &.rejected {
      color: @red;
    }
    &.reviewing {
      color: @orange;
    }
    &.normal {
      color: @text-color;
    }
  }
}

.subscribe-status-edit-modal {
  .ant-radio-group {
    width: 100%;
  }
  .block {
    display: block;
    margin: 16px 0;
  }
  .note-box {
    display: flex;
    padding: 0 24px;
    margin-bottom: 16px;

    .required-star {
      margin-right: 8px;
      color: @red;
    }
    input {
      flex: 1;
    }
  }
}
