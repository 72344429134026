@import '../../var.less';

.subscribe-rank-container {
  margin-top: 24px;
  padding: 24px 16px;
  background-color: white;

  .tip-box {
    display: flex;
    margin-bottom: 32px;
    align-items: center;
    .timeline {
      flex: 1;
      h1 {
        margin: 0 0 16px;
        font-size: 20px;
      }
      h2 {
        color: darkorange;
        font-size: 16px;
      }
      .tip {
        color: @text-color-secondary;
        font-size: 14px;
      }

      li:last-child {
        padding-bottom: 0;
      }
    }
    .qrcode-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
      width: 300px;
      .qrcode {
        width: 250px;
        height: 250px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .qrcode-desc {
        padding: 8px 64px 0;
        font-size: 14px;
        color: @text-color-secondary;
        text-align: center;
      }
    }
  }

  .important-bg {
    background-color: #fffbe6;
  }

  .table-title-tip {
    font-size: 12px;
    color: @text-color-secondary;
  }

  .yourself {
    font-weight: bold;
    td,
    a {
      color: darkorange;
    }
  }
}

.rank-qrcode-modal-info {
  width: 300px !important;
  i {
    display: none;
  }
  .ant-modal-confirm-title {
    margin-bottom: 16px;
    text-align: center;
  }
  .ant-modal-body,
  .ant-modal-confirm-content {
    margin: 0;
    text-align: center;
    img {
      width: 200px;
      height: 200px;
    }
  }
}
