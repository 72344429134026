.ck-editor__main {
  height: 100%;
}

.ck-content {
  height: 100%;
}

.ck-rounded-corners {
  height: 100% !important;
}
