.browser-tip-component .container-box {
  height: 100px;
}
.browser-tip-component .container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.browser-tip-component .container .chrome-tip {
  font-size: 20px;
}
.browser-tip-component .container .chrome-tip a {
  margin: 0 4px;
  text-decoration: underline;
}
