.Order__filter-bar-container {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;

  .Order__filter-bar-item {
    width: 150px;
    margin: 10px;
  }

  .datepicker-item {
    width: 240px;
  }

  .filter-bar-item--s {
    width: 100px;
  }

  .amount-input {
    width: 110px;
    &.amount-input--b {
      margin-right: 5px;
    }
    &.amount-input--s {
      margin-left: 5px;
    }
  }

  .checkbox-group {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}
