.container-plan-set-detail {
  background-color: white;
  padding: 16px;
  .back-btn {
    margin-bottom: 10px;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-card-body {
    padding: 12px;
  }

  .content-box {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 16px;
    height: ~'calc(100vh - 133px)';

    .content-left,
    .content-right {
      overflow-y: scroll;
    }

    > .title {
      margin: 20px 20px 5px;
    }

    .ant-card-head-title {
      .ant-form-item {
        margin-bottom: 0;
      }
    }

    .deleted-btn {
      margin-left: 20px;
    }

    .ant-card-bordered {
      margin-bottom: 20px;
    }

    .time {
      margin: 30px 0;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: 1px solid #f0f0f0;
    }
  }

  .btn-add-block {
    margin-bottom: 16px;
  }

  .form-grid {
    display: grid;
    grid-template-columns: auto 1fr auto;
    place-items: stretch;
    gap: 16px;

    hr {
      width: 100%;
      border: none;
      border-top: 1px solid #eee;
      margin: 0;
      padding: 0;
    }

    .form-label {
      padding: 4px 8px;
    }

    .grid-span2 {
      grid-column: span 2;
    }
    .grid-span3 {
      grid-column: span 3;
    }

    .grid-span3-flex {
      grid-column: span 3;
      display: flex;
      justify-content: space-between;

      .grid-span3-label {
        margin-right: 16px;
        padding: 4px 8px;
        width: 100px;
      }

      .grid-span3-select {
        flex: 1;
      }
    }
  }
  .spin {
    .ant-spin {
      top: 50% !important;
    }
  }
}
