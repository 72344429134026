.stories-container {
  margin-top: 24px;
  padding: 24px;
  background-color: white;
}
.stories-container .stories-box {
  margin-bottom: 16px;
}
.stories-container .stories-box .btns-box {
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #e8e8e8;
}
.stories-container .stories-box .btns-box .ant-alert {
  padding-right: 64px;
}
.stories-container .stories-box .btns-box .button-box {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}
.stories-container .item-desc.hidden {
  color: #f5222d;
}
.stories-container .item-desc.disabled {
  color: #999;
}
.stories-container .item-desc.rejected {
  color: #f5222d;
}
.stories-container .item-desc.reviewing {
  color: #1890ff;
}
.stories-container .item-desc.created {
  color: darkorange;
}
.stories-container .item-desc.normal {
  color: #999;
}
