@import '../../var.less';
.container-case-list {
  margin-top: 24px;
  padding: 16px;
  background-color: white;

  .color-green6 {
    color: #52c41a;
  }

  .color-red6 {
    color: #f5222d;
  }
}
