/** 全局主色 */
/** 链接色 */
/** 成功色 */
/** 警告色 */
/** 错误色 */
/** 主字号 */
/** 标题色 */
/** 主文本色 */
/** 次文本色 */
/** 失效色 */
/** 组件/浮层圆角 */
/** 边框色 */
/** 浮层阴影 */
.subscribe-rank-container {
  margin-top: 24px;
  padding: 24px 16px;
  background-color: white;
}
.subscribe-rank-container .tip-box {
  display: flex;
  margin-bottom: 32px;
  align-items: center;
}
.subscribe-rank-container .tip-box .timeline {
  flex: 1;
}
.subscribe-rank-container .tip-box .timeline h1 {
  margin: 0 0 16px;
  font-size: 20px;
}
.subscribe-rank-container .tip-box .timeline h2 {
  color: darkorange;
  font-size: 16px;
}
.subscribe-rank-container .tip-box .timeline .tip {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.subscribe-rank-container .tip-box .timeline li:last-child {
  padding-bottom: 0;
}
.subscribe-rank-container .tip-box .qrcode-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  width: 300px;
}
.subscribe-rank-container .tip-box .qrcode-box .qrcode {
  width: 250px;
  height: 250px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.subscribe-rank-container .tip-box .qrcode-box .qrcode-desc {
  padding: 8px 64px 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.subscribe-rank-container .important-bg {
  background-color: #fffbe6;
}
.subscribe-rank-container .table-title-tip {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}
.subscribe-rank-container .yourself {
  font-weight: bold;
}
.subscribe-rank-container .yourself td,
.subscribe-rank-container .yourself a {
  color: darkorange;
}
.rank-qrcode-modal-info {
  width: 300px !important;
}
.rank-qrcode-modal-info i {
  display: none;
}
.rank-qrcode-modal-info .ant-modal-confirm-title {
  margin-bottom: 16px;
  text-align: center;
}
.rank-qrcode-modal-info .ant-modal-body,
.rank-qrcode-modal-info .ant-modal-confirm-content {
  margin: 0;
  text-align: center;
}
.rank-qrcode-modal-info .ant-modal-body img,
.rank-qrcode-modal-info .ant-modal-confirm-content img {
  width: 200px;
  height: 200px;
}
