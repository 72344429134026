.browser-tip-component {
  @height: 100px;

  .container-box {
    height: @height;
  }
  .container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    display: flex;
    height: @height;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    .chrome-tip {
      font-size: 20px;
      a {
        margin: 0 4px;
        text-decoration: underline;
      }
    }
  }
}
