:root {
  --ck-highlight-marker-blue: #72cdfd;
  --ck-highlight-marker-green: #63f963;
  --ck-highlight-marker-pink: #fc7999;
  --ck-highlight-marker-yellow: #fdfd77;
  --ck-highlight-pen-green: #118800;
  --ck-highlight-pen-red: #e91313;
  --ck-image-style-spacing: 1.5em;
  --ck-todo-list-checkmark-size: 16px;
  --ck-spacing-standard: 11px;
  --ck-z-modal: 2000;
}
.ck.ck-balloon-panel.ck-toolbar-container {
  z-index: calc(var(--ck-z-modal) - 1);
}
.rich-text-preview-container {
  min-height: 240px;
  max-height: 70vh;
  border: 1px solid #d9d9d9;
  padding: 0 var(--ck-spacing-standard);
  word-break: break-all;
  color: #26343f;
  font-size: 14px;
  line-height: 1.75;
  font-family: 'PingFang SC', 'NotoSansCJKsc', 'DroidSans', 'Roboto', 'Helvetica Neue', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', 'Microsoft Yahei', sans-serif, Arial, Verdana;
}
.rich-text-preview-container h1,
.rich-text-preview-container h2,
.rich-text-preview-container h3,
.rich-text-preview-container h4,
.rich-text-preview-container h5,
.rich-text-preview-container h6,
.rich-text-preview-container p {
  margin: 1em 0;
}
.rich-text-preview-container h1 {
  font-size: 2em;
  font-weight: bold;
}
.rich-text-preview-container h2 {
  font-size: 1.5em;
  font-weight: bold;
}
.rich-text-preview-container h3 {
  font-size: 1.17em;
  font-weight: bold;
}
.rich-text-preview-container h4 {
  font-weight: bold;
}
.rich-text-preview-container a {
  color: #26343f;
  border-bottom: 0.1rem dotted #0c83fa;
  transition: all 0.2s;
}
.rich-text-preview-container a:hover {
  color: #0c83fa;
}
.rich-text-preview-container figure {
  margin: 1em 0;
}
.rich-text-preview-container figure figcaption {
  margin: 0.5em 0;
  font-size: 0.8em;
  text-align: center;
}
.rich-text-preview-container figure > table,
.rich-text-preview-container figure > img,
.rich-text-preview-container figure > video {
  margin: 0 auto;
}
.rich-text-preview-container video,
.rich-text-preview-container img,
.rich-text-preview-container table {
  display: block;
  max-width: 100%;
  margin: 1em auto;
}
.rich-text-preview-container table {
  width: min-content;
  border-collapse: collapse;
  overflow: auto;
  padding: 0;
  border-top: none;
  border-bottom: none;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  font-size: 0.8em;
  margin: 1.25em 0;
  color: #666;
}
.rich-text-preview-container table tbody {
  width: 100%;
  display: table;
}
.rich-text-preview-container table tr {
  margin: 0;
  padding: 0;
}
.rich-text-preview-container table tr td,
.rich-text-preview-container table tr th {
  min-width: 8em;
  margin: 0;
  padding: 0.7rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid #ededed;
  text-align: left;
}
.rich-text-preview-container table tr td:first-child,
.rich-text-preview-container table tr th:first-child {
  border-left: none;
}
.rich-text-preview-container table tr td:last-child,
.rich-text-preview-container table tr th:last-child {
  border-right: none;
}
.rich-text-preview-container ol {
  margin: 1em 0;
  padding-left: 2em;
  list-style: initial;
  list-style-type: decimal;
}
.rich-text-preview-container ol ol,
.rich-text-preview-container ol ul {
  margin: 0;
}
.rich-text-preview-container ol li {
  list-style: initial;
  list-style-type: decimal;
}
.rich-text-preview-container ul {
  margin: 1em 0;
  padding-left: 2em;
  list-style: initial;
}
.rich-text-preview-container ul ol,
.rich-text-preview-container ul ul {
  margin: 0;
}
.rich-text-preview-container ul li {
  list-style: initial;
}
.rich-text-preview-container code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
.rich-text-preview-container pre {
  padding: 1em;
  color: #353535;
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
.rich-text-preview-container pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
.rich-text-preview-container blockquote {
  overflow: hidden;
  padding: 0 1.5em;
  margin: 1em 0;
  font-style: italic;
  border-left: solid 0.5em hsl(0, 0%, 80%);
}
.rich-text-preview-container hr {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: hsl(0, 0%, 37%);
  margin: 0;
}
.rich-text-preview-container .text-indent-1 {
  text-indent: 2em;
}
.rich-text-preview-container .text-indent-2 {
  text-indent: 4em;
}
.rich-text-preview-container .text-indent-3 {
  text-indent: 6em;
}
.rich-text-preview-container figure,
.rich-text-preview-container img,
.rich-text-preview-container video,
.rich-text-preview-container table {
  margin: 1em auto;
  max-width: calc(100% - 4em);
}
.rich-text-preview-container figure.image-style-side {
  float: right;
  max-width: calc(50% - 4em);
}
.ck.ck-content {
  min-height: 240px;
  max-height: 70vh;
  border: 1px solid #d9d9d9;
  padding: 0 var(--ck-spacing-standard);
  word-break: break-all;
  color: #26343f;
  font-size: 14px;
  line-height: 1.75;
  font-family: 'PingFang SC', 'NotoSansCJKsc', 'DroidSans', 'Roboto', 'Helvetica Neue', 'Hiragino Sans GB', 'WenQuanYi Micro Hei', 'Microsoft Yahei', sans-serif, Arial, Verdana;
}
.ck.ck-content h1,
.ck.ck-content h2,
.ck.ck-content h3,
.ck.ck-content h4,
.ck.ck-content h5,
.ck.ck-content h6,
.ck.ck-content p {
  margin: 1em 0;
}
.ck.ck-content h1 {
  font-size: 2em;
  font-weight: bold;
}
.ck.ck-content h2 {
  font-size: 1.5em;
  font-weight: bold;
}
.ck.ck-content h3 {
  font-size: 1.17em;
  font-weight: bold;
}
.ck.ck-content h4 {
  font-weight: bold;
}
.ck.ck-content a {
  color: #26343f;
  border-bottom: 0.1rem dotted #0c83fa;
  transition: all 0.2s;
}
.ck.ck-content a:hover {
  color: #0c83fa;
}
.ck.ck-content figure {
  margin: 1em 0;
}
.ck.ck-content figure figcaption {
  margin: 0.5em 0;
  font-size: 0.8em;
  text-align: center;
}
.ck.ck-content figure > table,
.ck.ck-content figure > img,
.ck.ck-content figure > video {
  margin: 0 auto;
}
.ck.ck-content video,
.ck.ck-content img,
.ck.ck-content table {
  display: block;
  max-width: 100%;
  margin: 1em auto;
}
.ck.ck-content table {
  width: min-content;
  border-collapse: collapse;
  overflow: auto;
  padding: 0;
  border-top: none;
  border-bottom: none;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  font-size: 0.8em;
  margin: 1.25em 0;
  color: #666;
}
.ck.ck-content table tbody {
  width: 100%;
  display: table;
}
.ck.ck-content table tr {
  margin: 0;
  padding: 0;
}
.ck.ck-content table tr td,
.ck.ck-content table tr th {
  min-width: 8em;
  margin: 0;
  padding: 0.7rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid #ededed;
  text-align: left;
}
.ck.ck-content table tr td:first-child,
.ck.ck-content table tr th:first-child {
  border-left: none;
}
.ck.ck-content table tr td:last-child,
.ck.ck-content table tr th:last-child {
  border-right: none;
}
.ck.ck-content ol {
  margin: 1em 0;
  padding-left: 2em;
  list-style: initial;
  list-style-type: decimal;
}
.ck.ck-content ol ol,
.ck.ck-content ol ul {
  margin: 0;
}
.ck.ck-content ol li {
  list-style: initial;
  list-style-type: decimal;
}
.ck.ck-content ul {
  margin: 1em 0;
  padding-left: 2em;
  list-style: initial;
}
.ck.ck-content ul ol,
.ck.ck-content ul ul {
  margin: 0;
}
.ck.ck-content ul li {
  list-style: initial;
}
.ck.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
.ck.ck-content pre {
  padding: 1em;
  color: #353535;
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
.ck.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
.ck.ck-content blockquote {
  overflow: hidden;
  padding: 0 1.5em;
  margin: 1em 0;
  font-style: italic;
  border-left: solid 0.5em hsl(0, 0%, 80%);
}
.ck.ck-content hr {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: hsl(0, 0%, 37%);
  margin: 0;
}
.ck.ck-content .text-indent-1 {
  text-indent: 2em;
}
.ck.ck-content .text-indent-2 {
  text-indent: 4em;
}
.ck.ck-content .text-indent-3 {
  text-indent: 6em;
}
.ck.ck-content figure,
.ck.ck-content img,
.ck.ck-content video,
.ck.ck-content table {
  margin: 1em auto;
  max-width: calc(100% - 4em);
}
.ck.ck-content figure.image-style-side {
  float: right;
  max-width: calc(50% - 4em);
}
.ck.ck-content.ck-editor__editable_inline {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ck.ck-content.ck-editor__editable_inline:hover {
  border-color: #40a9ff;
}
.ck.ck-content.ck-editor__editable_inline.ck-focused,
.ck.ck-content.ck-editor__editable_inline:focus {
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
  outline: 0;
}
.ant-form-item-has-error .ck.ck-content.ck-editor__editable_inline {
  border-color: #ff4d4f !important;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
}
