.component-image-viewer {
  .outer-mask {
    position: fixed;
    background-color: #000;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10086;
  }

  .image-box {
    width: 100%;
    height: 100vh;
    text-align: center;
    img {
      position: relative;
      max-width: 60%;
      max-height: 90%;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0 0 100px rgba(255, 255, 255, 0.15);
    }
  }
}
