.container-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 96px);
}
.container-profile .box .text {
  margin-top: 20px;
  font-size: 15px;
  color: black;
  font-weight: bold;
  text-align: center;
}
