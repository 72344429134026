.stories-container {
  margin-top: 24px;
  padding: 24px;
  background-color: white;
  .stories-box {
    margin-bottom: 16px;
    .btns-box {
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid #e8e8e8;
      .ant-alert {
        padding-right: 64px;
      }
      .button-box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
      }
    }
  }

  .item-desc {
    &.hidden {
      color: #f5222d;
    }
    &.disabled {
      color: #999;
    }
    &.rejected {
      color: #f5222d;
    }
    &.reviewing {
      color: #1890ff;
    }
    &.created {
      color: darkorange;
    }
    &.normal {
      color: #999;
    }
  }
}
