.login-container {
  position: relative;
  height: 100vh;
  background-position: center;
  background-size: cover;

  h1 {
    margin-bottom: 24px;
    text-align: center;
    font-size: 22px;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .login-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 50%;
    top: 0;
    height: 100vh;
    transform: translateX(50%);
    justify-content: center;
    align-items: center;
    @bg: rgba(255, 255, 255, 1);
    .top {
      flex: 1;
      width: 100%;
    }
    .form {
      padding: 56px 40px 48px;
      background-color: @bg;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      .form-item-container {
        padding: 0 16px;
      }

      .ant-input {
        padding: 0 80px 0 0;
        height: 42px;
        line-height: 42px;
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 1px solid #e4e5e6;
        color: rgba(0, 0, 0, 0.85);
        outline: none;

        &:focus {
          outline: none;
          box-shadow: none;
          border-bottom-color: #1890ff;
        }
      }

      .item {
        position: relative;
        .send-btn,
        .captcha-box {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 80px;
          margin: auto 0;
          text-align: right;
          color: #1890ff;
        }
        .captcha-box {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .bottom {
      flex: 2;
    }
  }

  .send-btn {
    text-align: center;
    cursor: pointer;
    user-select: none;
    &:hover {
      color: #39f;
    }
  }

  .submit-btn {
    margin-top: 24px;
    width: 100%;
    height: 40px;
    font-size: 16px;
  }
}
